import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"
import { Row, Col, Container } from "react-bootstrap"

const AllProducts = ({ data }) => {
    console.log(data)
    return (
    <Layout pageInfo={{ pageName: "all-products" }}>
        <SEO title="Products" description="Crimson R Games products page. Find information about Crimson R Games apps and games" />
      <Row >
        <Col>
          <Container className="mt-4 ">
            <h2>Products:</h2>
            <hr></hr>
            {data.allFile.edges.map(row => (
              <ArticlePreview
                key={row.node.childMarkdownRemark.frontmatter.title}
                title={row.node.childMarkdownRemark.frontmatter.title}
                date={row.node.childMarkdownRemark.frontmatter.date}
                excerpt={row.node.childMarkdownRemark.excerpt}
                slug={row.node.childMarkdownRemark.fields.slug}
              ></ArticlePreview>
            ))}
          </Container>
        </Col>
      </Row>
    </Layout>
)}
export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "products"}}, sort: {order: DESC, fields: childMarkdownRemark___frontmatter___date}) {
      edges {
        node {
          id
          name
          childMarkdownRemark {
            id
            excerpt
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`
export default AllProducts
